<template>
    <div>

        <div class="app flex-row align-items-center" v-if="baggageInfo.length!== 0">
            <div class="container-fluid" style="z-index:2" v-for="info in baggageInfo.slice(0,1)" :key="info.id">

                <b-card no-body style="margin-bottom: 10px; margin-top: 10px">
                    <p style="text-align: center; font-size: 145px; margin: 0"><strong>Baggage Claim
                        {{ baggageNum }}</strong></p>
                </b-card>

                <b-card-group style="height: 58.5vh">
                    <b-card no-body class="p-4" style="margin-right: 6px; margin-bottom: 10px">
                        <p style=" font-size: 50px"><strong>Arriving Flight:</strong></p>
                        <span
                            style="text-align: center; font-size: 90px; line-height: 1">Flight {{ info.flight }}</span>
                        <span style="text-align: center; color: grey; font-size: 40px">Gate {{ info.gate }}</span>
                        <p style=" font-size: 50px"><strong>Arriving From:</strong></p>
                        <p style="text-align: center; font-size: 75px">
                            {{ citify(info.departing) }}({{ info.departing }}) at
                            {{ info.departuretime.slice((info.departuretime).indexOf(" ")) }}</p>
                    </b-card>
                    <b-card no-body class="text-white bg-primary py-5 d-md-down-none"
                            style="width:44%; margin-left: 6px; margin-bottom: 10px">
                        <b-card-body class="text-center">
                            <span style="display: flex; align-items: baseline; justify-content: center; height: inherit">
                              <img class="correctCenter" style="padding: 10px" :src="mapAirlineLogo(info.airline)" width="100%" alt="">
                            </span>
                        </b-card-body>
                    </b-card>
                </b-card-group>

                <b-card style="height: 200px; margin-bottom: 10px">
                    <div style="display: flex; flex-direction: column">
                      <h1 style="text-align: center; font-size: 40px">Current Time</h1>
                      <h1 style="text-align: center; font-size: 50px"><strong>{{ time }}</strong></h1>
                      <h3 style="text-align: center; color: grey">Thank You For Flying With Us</h3>
                    </div>
                </b-card>

            </div>
        </div>



        <div v-else class="app flex-row align-items-center" id="particles-js">
            <b-form style="width: 100%; z-index: 999">
                <img style=" display: block;margin-left: auto; margin-right: auto"
                     src="../../../../public/img/mtoplogo2.png" width="80%" alt="">
                <h1 class="display-3 text-center"><small><b>{{ time }}</b></small></h1>
            </b-form>
        </div>

    </div>
</template>

<script>

import fcr from '../../../services/fcr.js'
import '../../../../node_modules/particles.js/particles';
import * as moment from 'moment';
import blackhole from "@/services/blackhole";

export default {

    name: "fids_baggage",
    props: ["id"],

    data() {
        return {
            time: "",
            baggageInfo: [],
            baggageNum: this.$route.params.id,
            airlines: null,
            from: "",
        }
    },

    methods: {
        liveTime() {
            this.time = moment(Date.now(), 'x').format('LLL');
            setTimeout(this.liveTime, 500);
        },
        getBaggage() {
            let result = [];
            fcr.readFlights().then(response => {
                response.data.some(function (data) {
                    if (data.baggageclaimnumber === this.$route.params.id && data.type === "Arriving" && new Date().getTime() >= parseInt(data.rollIn) && new Date().getTime() < parseInt(data.rollOut)) {
                        result.push(data);
                        return true;
                    }
                }.bind(this));
                this.baggageInfo = result;
            });
            setTimeout(this.getBaggage, 5000);
        },
        mapAirlineLogo(airlineName) {
            for (let airline of this.airlines) {
                if (airline.name === airlineName) {
                    return this.logo(airline.image);
                }
            }
        },
        logo(logoId) {
            if (!logoId) {
                return "";
            } else {
                return blackhole.readImage(logoId);
            }
        },
        initParticles() {
            window.particlesJS("particles-js", {
                "particles": {
                    "number": {
                        "value": 80,
                        "density": {
                            "enable": true,
                            "value_area": 700
                        }
                    },
                    "color": {
                        "value": "#ffffff"
                    },
                    "shape": {
                        "type": "circle",
                        "stroke": {
                            "width": 0,
                            "color": "#000000"
                        },
                        "polygon": {
                            "nb_sides": 5
                        },
                    },
                    "opacity": {
                        "value": 0.5,
                        "random": false,
                        "anim": {
                            "enable": false,
                            "speed": 1,
                            "opacity_min": 0.1,
                            "sync": false
                        }
                    },
                    "size": {
                        "value": 5,
                        "random": true,
                        "anim": {
                            "enable": false,
                            "speed": 40,
                            "size_min": 0.1,
                            "sync": false
                        }
                    },
                    "line_linked": {
                        "enable": true,
                        "distance": 150,
                        "color": "#ffffff",
                        "opacity": 0.4,
                        "width": 1
                    },
                    "move": {
                        "enable": true,
                        "speed": 6,
                        "direction": "none",
                        "random": false,
                        "straight": false,
                        "out_mode": "out",
                        "bounce": false,
                        "attract": {
                            "enable": false,
                            "rotateX": 600,
                            "rotateY": 1200
                        }
                    }
                },
                "interactivity": {
                    "detect_on": "canvas",
                    "events": {
                        "onhover": {
                            "enable": false,
                            "mode": "grab"
                        },
                        "onclick": {
                            "enable": true,
                            "mode": "push"
                        },
                        "resize": true
                    },
                    "modes": {
                        "grab": {
                            "distance": 140,
                            "line_linked": {
                                "opacity": 1
                            }
                        },
                        "bubble": {
                            "distance": 400,
                            "size": 40,
                            "duration": 2,
                            "opacity": 8,
                            "speed": 3
                        },
                        "repulse": {
                            "distance": 200,
                            "duration": 0.4
                        },
                        "push": {
                            "particles_nb": 4
                        },
                        "remove": {
                            "particles_nb": 2
                        }
                    }
                },
                "retina_detect": true
            })
        },
        citify(place) {
            fcr.readAirportInfo(place).then(response => {
                this.from = response.data.city;
            });
            return (this.from === "" ? place : this.from);
        }
    },

    created() {
        fcr.readAirlines().then(response => {
            this.airlines = response.data;
        });
        this.getBaggage();
        this.liveTime();
    },

    mounted() {
        this.initParticles();
    }

}
</script>

<style>
#particles-js {
    background: #2f353a;
}

canvas {
    position: absolute;
    z-index: 1;
}

.correctCenter {
    position: absolute;
    max-width: 100%;
    max-height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    object-fit: contain;
    width: 100%;
}
</style>